<template>
  <div class="mobile-agreement flex-col align-center">
    <Nav />
    <div class="content flex-col">
      <p class="title">可搭吖用户服务协议</p>
      <p class="title-desc">发布日期：2024年【7】月【18】日</p>
      <p class="title-desc mb28">生效日期：2024年【7】月【25】日</p>
      <p class="paragraph bold">前言</p>
      <p class="paragraph">尊敬的用户:</p>
      <p class="paragraph">感谢您选择注册并使用可搭吖及可搭吖服务。在使用可搭吖及可搭吖服务前,您应当仔细阅读并遵守《可搭吖用户服务协议》(以下简称"本协议")以及《可搭吖社区管理公约》。请您务必审慎阅读、充分理解各条款内容,特别是免责声明条款、管辖与适用法律条款,以及开通或使用某项服务的单独协议。<span class="bold">免责声明条款或其他涉及您重大权益的条款可能以加粗形式提示您重点注意。</span></p>
      <p class="paragraph">除非您已充分阅读、完全理解并接受本协议所有条款,否则您无权使用可搭吖服务。您使用可搭吖服务,或者以其他任何明示或默示方式表示接受本协议的,均视为您已阅读并同意签署本协议。本协议即在您与本公司之间产生法律效力,成为对双方均具有约束力的法律文件。</p>
      <p class="paragraph bold">如果您未满18周岁,请在法定监护人的陪同下阅读本协议,并特别注意未成年人使用条款。</p>
      
      <p class="paragraph-title">一. 协议条款的确认及接受</p>
      <p class="paragraph"><span class="paragraph-index">1.1</span> 可搭吖(包括网址为psydusk.com.cn的网站,以及可在iOS系统及Android系统中运行的名为"可搭吖"及其他不同版本的应用程序,以下简称"可搭吖")由深圳市萨克森计算机有限公司(包括其关联机构,以下合称"本公司")运营并享有完全的所有权及知识产权等权益,可搭吖提供的服务将完全按照其发布的条款和操作规则严格执行。</p>
      <p class="paragraph"><span class="paragraph-index">1.2</span> 您确认同意本协议所有条款并完成注册程序时,您成为可搭吖正式用户,视为您已充分理解本协议,并同意作为本协议的一方当事人接受本协议、《可搭吖用户隐私保护政策》、《可搭吖社区管理公约》及可搭吖已公示或将来公示的各项规则及提示的约束;所有前述协议、规则及提示乃不可分割的整体,具有同等法律效力,共同构成您使用可搭吖及可搭吖服务的整体协议,在您与本公司间成立并发生法律效力。</p>
      
      <p class="paragraph-title">二. 服务说明</p>
      <p class="paragraph"><span class="paragraph-index">2.1</span> 可搭吖向您提供包括但不限于如下软件或服务(合称"可搭吖服务"):</p>
      <p class="paragraph"><span class="paragraph-index">2.1.1</span> 可搭吖直接拥有或运营的包括但不限于PC、平板、手机等全部终端客户端产品,如可搭吖App等;</p>
      <p class="paragraph"><span class="paragraph-index">2.1.2</span> 可搭吖直接拥有或运营的服务器、网络存储空间;</p>
      <p class="paragraph"><span class="paragraph-index">2.1.3</span> 可搭吖提供给您的其他软件、技术或服务。</p>
      <p class="paragraph"><span class="paragraph-index">2.2</span> 您使用可搭吖服务,可以通过预装、可搭吖内下载、可搭吖已授权的第三方下载等方式获取相关客户端或访问可搭吖网。若您并非从前述途径,而是通过其他任何途径、渠道、方式获取可搭吖服务的(包括但不限于帐号、可搭吖币、客户端下载等)均为非法行为,可搭吖概不承认其效力,一经发现,可搭吖有权立即作出删除、清零、封号等处理,任何因此导致的一切不利后果均由您自行承担。</p>
      <p class="paragraph"><span class="paragraph-index">2.3</span> 可搭吖可能为不同的终端设备开发了不同的应用程序软件版本,您应当根据实际设备状况获取、下载、安装合适的版本。为更好的提升用户体验及服务,可搭吖将不时提供软件更新或改变(包括但不限于软件修改、升级、功能强化、开发新服务、软件替换、暂停或终止某项服务等),可搭吖将以包括但不限于系统提示、公告、站内信等方式提示您更新、下载或使用,您有权选择接受更新版本或服务,如果您不同意或者不接受可搭吖相关软件或服务的改变,请直接拒绝、停止、取消使用行为,否则视为您同意并接受改变,同时该同意并接受的行为仍受本协议约束。</p>
      <p class="paragraph"><span class="paragraph-index">2.4</span> 您仅可以合法使用可搭吖服务为目的或方式而使用可搭吖服务,除非得到可搭吖明示事先书面授权,否则,您不得以任何形式改编、复制、传播、垂直搜索、镜像或交易可搭吖服务。可搭吖许可您个人的、不可转让的、非独占地和非商业的合法使用可搭吖服务的权利。本协议未明示授权的其他一切权利仍由可搭吖保留,您在行使该些权利时须另行获得可搭吖的书面许可,同时可搭吖如未行使前述任何权利,并不构成对该权利的放弃。</p>
      
      <p class="paragraph-title">三. 帐号注册及使用规则</p>
      <p class="paragraph"><span class="paragraph-index">3.1</span> 您可以创建可搭吖帐号,以便您更好地使用可搭吖服务。另外,某些服务需要您拥有可搭吖帐号才能使用。您应对自己使用可搭吖帐号所执行的操作负责,包括采取合理的措施来保障帐号的安全。您也可以选择注销您的可搭吖帐号,但帐号注销后,可搭吖无义务为您保留与账户有关的任何信息。</p>
      <p class="paragraph"><span class="paragraph-index">3.2</span> 若您在可搭吖帐号注册过程中提供的信息不准确,不真实,含有违法或不良信息的,可搭吖有权不予注册,并保留终止您使用可搭吖服务的权利。若您以虚假信息骗取帐号注册或帐号头像、个人简介等注册资料存在违法和不良信息的,可搭吖有权视情形采取通知限期改正、暂停使用、注销帐号等措施。对于冒用他人身份包括关联机构或社会名人注册帐号的,可搭吖有权注销该帐号,并向政府主管部门进行报告。</p>
      <p class="paragraph"><span class="paragraph-index">3.3</span> 您进行实名认证时,应提供您本人真实、合法、准确、有效的身份信息及其他相关信息,且不得以他人身份资料、手机号进行实名认证。否则可搭吖有权终止为您提供服务,并有权对您的帐号采取包括但不限于警告、限制或禁止使用帐号全部或部分功能、删除帐号及数据、删除相关信息的处理措施,由此造成的一切后果由您自行承担。</p>
      <p class="paragraph"><span class="paragraph-index">3.4</span> 部分可搭吖服务仅向实名认证用户提供,如果您使用可搭吖提供的私聊、创建群聊等功能,请先根据本协议及其他可搭吖规则提示等规则、流程完成实名认证,并确保实名信息的真实性、正确性及完整性。若您不提供或提供的认证信息不完整则您可能无法使用可搭吖部分服务或在使用可搭吖的过程中会受到相应限制。您应持续保证您所提供的信息的合法性和真实性。为此目的,您应及时更新您提供的所有信息。否则,如可搭吖按您最后一次提供的信息与您联系未果、您未按可搭吖的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的,您将承担因此对其自身、他人及可搭吖造成的全部损失与不利后果。</p>
      <p class="paragraph"><span class="paragraph-index">3.5</span> 您理解并同意,依据政策法规的要求,及时提供真实准确的帐号注册资料。可搭吖提醒您,一般情况下,您可随时浏览、修改、删除您提供的个人信息,为了保障您的帐号安全(如帐号或密码找回申诉服务等),您可能无法修改注册时提供的初始注册信息及身份信息,请您慎重填写相关信息。</p>
      <p class="paragraph"><span class="paragraph-index">3.6</span> 您理解并同意,除相关权利人依照法律规定应享有的权利外,您使用可搭吖帐号产生的相关数据和记录,包括但不限于可搭吖帐号、登录、活动、消费记录和相关的统计数据等归可搭吖所有。发生争议时,您同意以可搭吖提供的数据为准。</p>
      <p class="paragraph"><span class="paragraph-index">3.7</span> 尽管有前述约定,对于第三方提供的单项服务产生的数据,包括但不限于单项服务登录、活动、消费记录及其他产品日志、产品客户服务记录归第三方所有。发生争议时,您同意以第三方提供的数据为准。</p>
      <p class="paragraph"><span class="paragraph-index">3.8</span> 可搭吖特别提醒您应妥善保管您的可搭吖帐号。当您使用完毕后,应安全退出。因您保管不善可能导致遭受盗号的,责任由您自行承担。</p>
      <p class="paragraph"><span class="paragraph-index">3.9</span> 如果您代表某组织(如公司、非营利组织及其他非个人组织)使用可搭吖的服务,您应取得该组织的授权证明,同时该组织的授权代表也需要同意本协议。对可搭吖或第三方造成损失的,该组织应当依法承担赔偿责任。</p>
      <p class="paragraph"><span class="paragraph-index">3.10</span> 若您发现有他人冒用或盗用您的帐号、或任何其他未经您合法授权使用的情形时,应立即以可搭吖披露的方式通知可搭吖并告知可搭吖需采取的措施。您通知可搭吖时,应提供与您注册身份信息相一致的个人有效身份信息,可搭吖收到您的有效请求并核实身份后,会根据您的要求或结合具体情况采取相应措施(包括但不限于暂停该帐号的登录和使用等),可搭吖因根据您的请求采取相应措施而造成您及其他用户损失的,由您自行承担。若您没有提供有效身份信息或您提供的个人有效身份信息与所注册的身份信息不一致的,可搭吖有权拒绝您的请求,因此造成您损失的,由您自行承担。</p>
      <p class="paragraph"><span class="paragraph-index">3.11</span> 您理解并同意,您所设置的帐号不得违反国家法律法规及可搭吖的相关规则,不得实施任何侵害国家利益、损害其他公民合法权益,有害社会道德风尚的行为,您的帐号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息,未经他人许可不得用他人名义(包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式)开设帐号,不得恶意注册可搭吖帐号(包括但不限于频繁注册、批量注册帐号等行为)。</p>
      <p class="paragraph"><span class="paragraph-index">3.12</span> 您在可搭吖的注册帐号仅限于您本人使用,未经本公司书面同意,禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该帐号。</p>
      <p class="paragraph"><span class="paragraph-index">3.13</span> 为了充分使用帐号资源,如您在注册后未及时进行初次登录使用或连续超过一年未登录帐号并使用等情形,本公司有权收回您的注册帐号。</p>
      
      <p class="paragraph-title">四. 个人信息保护</p>
      <p class="paragraph"><span class="paragraph-index">4.1</span> 保护您的个人信息是可搭吖的一项基本原则。可搭吖将按照本协议及《可搭吖用户隐私保护政策》的规定收集、使用、储存和分享您的个人信息。本协议对个人信息保护规定的内容与上述《可搭吖用户隐私保护政策》有相冲突的,及本协议对个人信息保护相关内容未作明确规定的,均以《可搭吖用户隐私保护政策》的内容为准。</p>
      <p class="paragraph"><span class="paragraph-index">4.2</span> 可搭吖将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。</p>
      <p class="paragraph"><span class="paragraph-index">4.3</span> 我们可能基于以下法律原因披露您的个人信息:</p>
      <p class="paragraph"><span class="paragraph-index">4.3.1</span> 遵守适用的法律法规等有关规定;</p>
      <p class="paragraph"><span class="paragraph-index">4.3.2</span> 遵守法院判决、裁定或其他法律程序的规定;</p>
      <p class="paragraph"><span class="paragraph-index">4.3.3</span> 遵守相关政府机关或其他法定授权组织的要求;</p>
      <p class="paragraph"><span class="paragraph-index">4.3.4</span> 我们有理由确信需要遵守法律法规等有关规定;</p>
      <p class="paragraph"><span class="paragraph-index">4.3.5</span> 为执行相关服务协议或隐私政策、维护社会公共利益,为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途。</p>
      
      <p class="paragraph-title">五. 用户行为规范</p>
      <p class="paragraph"><span class="paragraph-index">5.1</span> 您在使用可搭吖服务时须遵守法律法规,不得利用可搭吖服务从事违法违规行为,包括但不限于:</p>
      <p class="paragraph"><span class="paragraph-index">5.1.1</span> 发布、传送、传播、储存违反国家法律法规禁止的内容:<br>· 违反宪法确定的基本原则的;<br>· 危害国家安全,泄露国家秘密,颠覆国家政权,破坏国家统一的;<br>· 损害国家荣誉和利益的;<br>· 煽动民族仇恨、民族歧视,破坏民族团结的;<br>· 破坏国家宗教政策,宣扬邪教和封建迷信的;<br>· 散布谣言,扰乱社会秩序,破坏社会稳定的;<br>· 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的;<br>· 侮辱或者诽谤他人,侵害他人合法权益的;<br>· 含有法律、行政法规禁止的其他内容的。</p>
      <p class="paragraph"><span class="paragraph-index">5.1.2</span> 发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容;</p>
      <p class="paragraph"><span class="paragraph-index">5.1.3</span> 涉及他人隐私、个人信息或资料的;</p>
      <p class="paragraph"><span class="paragraph-index">5.1.4</span> 发表、传送、传播骚扰、广告信息及垃圾信息或含有任何性或性暗示的;</p>
      <p class="paragraph"><span class="paragraph-index">5.1.5</span> 其他违反法律法规、政策及公序良俗、社会公德或干扰可搭吖正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>
      <p class="paragraph"><span class="paragraph-index">5.2</span> 您不得利用可搭吖服务进行任何违法或不正当的活动,包括但不限于:</p>
      <p class="paragraph"><span class="paragraph-index">5.2.1</span> 未经允许,进入计算机信息网络或者使用计算机信息网络资源的;</p>
      <p class="paragraph"><span class="paragraph-index">5.2.2</span> 未经允许,对计算机信息网络功能进行删除、修改或者增加的;</p>
      <p class="paragraph"><span class="paragraph-index">5.2.3</span> 未经允许,对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的;</p>
      <p class="paragraph"><span class="paragraph-index">5.2.4</span> 故意制作、传播计算机病毒等破坏性程序的;</p>
      <p class="paragraph"><span class="paragraph-index">5.2.5</span> 其他危害计算机信息网络安全的行为。</p>
      <p class="paragraph"><span class="paragraph-index">5.3</span> 您不得通过不正当手段或其他不正当方式使用可搭吖服务或干扰可搭吖服务的正常运行,包括但不限于:</p>
      <p class="paragraph"><span class="paragraph-index">5.3.1</span> 攻击、侵入可搭吖的网站、应用服务器或其他计算机系统;</p>
      <p class="paragraph"><span class="paragraph-index">5.3.2</span> 干扰或破坏可搭吖服务或与可搭吖服务相连接的服务器和网络;</p>
      <p class="paragraph"><span class="paragraph-index">5.3.3</span> 利用可搭吖服务发送垃圾邮件或滥用可搭吖服务以散发大量信息;</p>
      <p class="paragraph"><span class="paragraph-index">5.3.4</span> 通过修改或伪造软件运行中的指令、数据,增加、删减、变动软件的功能或运行效果,或者将用于上述用途的软件通过信息网络向公众传播的;</p>
      <p class="paragraph"><span class="paragraph-index">5.3.5</span> 通过非可搭吖开发、授权的第三方软件、插件、外挂、系统,登录或使用可搭吖服务,或制作、发布、传播上述工具;</p>
      <p class="paragraph"><span class="paragraph-index">5.3.6</span> 其他危害可搭吖服务安全或者干扰可搭吖服务正常运营的行为。</p>
      <p class="paragraph"><span class="paragraph-index">5.4</span> 您理解并同意,可搭吖有权应国家有关机关的要求,向其提供您在可搭吖的用户信息和使用记录等必要信息。如您涉嫌侵犯他人知识产权,则可搭吖亦有权在初步判断涉嫌侵权行为存在的情况下,向权利人提供您必要的身份信息。</p>

      <p class="paragraph-title">六. 知识产权声明</p>
      <p class="paragraph"><span class="paragraph-index">6.1</span> 可搭吖是可搭吖服务的知识产权权利人。可搭吖服务涉及的所有注册商标、版权、专利、商业秘密及其他知识产权,以及与可搭吖服务相关的所有信息内容(包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等)均受中华人民共和国法律法规和相应的国际条约保护,可搭吖享有上述知识产权。</p>
      <p class="paragraph"><span class="paragraph-index">6.2</span> 未经可搭吖或相关权利人书面同意,您不得为任何商业或非商业目的自行或许可任何第三方实施、使用、转让上述知识产权。</p>
     
      <p class="paragraph-title">七. 用户内容规范</p>
      <p class="paragraph"><span class="paragraph-index">7.1</span> 用户内容是指用户使用可搭吖服务过程中所产生的内容,包括但不限于文字、图片、视频、音频等。</p>
      <p class="paragraph"><span class="paragraph-index">7.2</span> 您对您使用可搭吖服务过程中所产生的内容负责,保证其不违反相关法律法规、本协议及相关协议、规则等的规定。</p>
      <p class="paragraph"><span class="paragraph-index">7.3</span> 可搭吖提供的服务中包含的由用户上传的信息内容(包括但不限于网页、文字、图片、音频、视频、图表等)均由用户自行上传,由用户对其上传的信息承担相应的法律责任。</p>
      <p class="paragraph"><span class="paragraph-index">7.4</span> 您使用可搭吖服务上传、发布或传送内容即视为您保证:</p>
      <p class="paragraph"><span class="paragraph-index">7.4.1</span> 您对该等内容拥有相应的知识产权或已获得合法授权;</p>
      <p class="paragraph"><span class="paragraph-index">7.4.2</span> 您的上传行为未侵犯任何第三方的合法权益;</p>
      <p class="paragraph"><span class="paragraph-index">7.4.3</span> 您将对该等内容承担全部责任。</p>
      <p class="paragraph"><span class="paragraph-index">7.5</span> 可搭吖不对用户发布的内容的正确性、完整性或品质做出任何保证。您已经同意,使用可搭吖服务所存在的风险将完全由您个人承担。</p>
      <p class="paragraph"><span class="paragraph-index">7.6</span> 您理解并同意,可搭吖有权依合理判断对违反有关法律法规或本协议规定的内容予以删除或采取其他相应措施。</p>
      
      <p class="paragraph-title">八. 第三方链接</p>
      <p class="paragraph">可搭吖服务可能会提供与其他国际互联网网站或资源进行链接。除非另有声明,可搭吖无法对第三方网站之服务进行控制,您使用或依赖上述网站或资源所产生的损失或损害,可搭吖不承担任何责任。我们建议您在使用上述链接时查看其服务条款和隐私政策。</p>
      
      <p class="paragraph-title">九. 服务的变更、中断、终止</p>
      <p class="paragraph"><span class="paragraph-index">9.1</span> 您理解并同意,可搭吖基于经营策略的调整,可能会对服务内容进行变更,也可能会中断、中止或终止服务。</p>
      <p class="paragraph"><span class="paragraph-index">9.2</span> 您理解并同意,如发生下列任何一种情形,可搭吖有权不经通知而中断或终止向您提供的服务:</p>
      <p class="paragraph"><span class="paragraph-index">9.2.1</span> 根据法律规定您应提交真实信息,而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明;</p>
      <p class="paragraph"><span class="paragraph-index">9.2.2</span> 您违反相关法律法规或本协议的约定;</p>
      <p class="paragraph"><span class="paragraph-index">9.2.3</span> 按照法律规定或有权机关的要求;</p>
      <p class="paragraph"><span class="paragraph-index">9.2.4</span> 出于安全的原因或其他必要的情形。</p>
      <p class="paragraph"><span class="paragraph-index">9.3</span> 您有责任自行备份存储在可搭吖服务中的数据。如果您的服务被终止,可搭吖可以从服务器上永久地删除您的数据。服务终止后,可搭吖没有义务向您返还数据。</p>
     
      <p class="paragraph-title">十. 免责声明</p>
      <p class="paragraph"><span class="paragraph-index">10.1</span> 您理解并同意,可搭吖服务是按照现有技术和条件所能达到的现状提供的。可搭吖会尽最大努力向您提供服务,确保服务的连贯性和安全性;但可搭吖不能保证其所提供的服务毫无瑕疵,也无法随时预见和防范法律、技术以及其他风险,包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。</p>
      <p class="paragraph"><span class="paragraph-index">10.2</span> 您理解并同意,可搭吖的服务并非为某些特定目的而设计,包括但不限于核设施、军事用途、医疗设备、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等,可搭吖不承担任何责任。</p>
      <p class="paragraph"><span class="paragraph-index">10.3</span> 在法律允许的最大范围内,可搭吖对以下情形导致的服务中断或受阻不承担责任:</p>
      <p class="paragraph"><span class="paragraph-index">10.3.1</span> 受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏;</p>
      <p class="paragraph"><span class="paragraph-index">10.3.2</span> 用户或可搭吖的电脑软件、系统、硬件和通信线路出现故障;</p>
      <p class="paragraph"><span class="paragraph-index">10.3.3</span> 用户操作不当;</p>
      <p class="paragraph"><span class="paragraph-index">10.3.4</span> 用户通过非可搭吖授权的方式使用可搭吖服务;</p>
      <p class="paragraph"><span class="paragraph-index">10.3.5</span> 其他可搭吖无法控制或合理预见的情形。</p>
      <p class="paragraph"><span class="paragraph-index">10.4</span> 可搭吖依据本协议约定获得处理违法违规内容的权利,该权利不构成可搭吖的义务或承诺,可搭吖不能保证及时发现违法行为或进行相应处理。</p>
      <p class="paragraph"><span class="paragraph-index">10.5</span> 在任何情况下,您因使用可搭吖服务而遭受的损失,可搭吖均不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任。</p>
     
      <p class="paragraph-title">十一. 协议的生效与变更</p>
      <p class="paragraph"><span class="paragraph-index">11.1</span> 您使用可搭吖服务即视为您已阅读并同意受本协议的约束。</p>
      <p class="paragraph"><span class="paragraph-index">11.2</span> 可搭吖有权在必要时修改本协议条款。您可以在可搭吖服务的最新版本中查阅相关协议条款。本协议条款变更后,如果您继续使用可搭吖服务,即视为您已接受修改后的协议。如果您不接受修改后的协议,应当停止使用可搭吖服务。</p>
     
      <p class="paragraph-title">十二. 通知送达</p>
      <p class="paragraph"><span class="paragraph-index">12.1</span> 本协议项下可搭吖对于用户所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行;该等通知于发送之日视为已送达收件人。</p>
      <p class="paragraph"><span class="paragraph-index">12.2</span> 用户对于可搭吖的通知应当通过可搭吖对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。</p>

      <p class="paragraph-title">十三. 法律适用</p>
      <p class="paragraph"><span class="paragraph-index">13.1</span> 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
      <p class="paragraph"><span class="paragraph-index">13.2</span> 如双方就本协议内容或其执行发生任何争议,双方应尽量友好协商解决;协商不成时,任何一方均可向可搭吖所在地的人民法院提起诉讼。</p>

      <p class="paragraph-title">十四. 其他规定</p>
      <p class="paragraph"><span class="paragraph-index">14.1</span> 本协议构成双方对本协议之约定事项及其他有关事宜的完整协议,除本协议规定的之外,未赋予本协议各方其他权利。</p>
      <p class="paragraph"><span class="paragraph-index">14.2</span> 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力,本协议的其余条款仍应有效并且有约束力。</p>
    </div>
    <goTop/>
    <Footer />
  </div>
</template>

<script>
import Nav from './components/Nav'
import Footer from './components/Footer'
import goTop from './components/goTop.vue'
export default {
  name: 'MobileAgreement',
  components: {
    Nav,
    Footer,
    goTop
  },
  data () {
      return {
          
      }
  },
  methods: {
      
  }
}
</script>

<style lang="scss" scoped>
.mobile-agreement {
  width: 375rem;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  padding-top: 50rem;
  background: #F7F7F7;
  .content {
    width: 343rem;
    min-height: calc(100vh - 470rem);
    padding-bottom: 16rem;
    .title {
      width: 100%;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 16rem;
      color: #0B162B;
      line-height: 19rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin: 24rem 0 28rem;
    }
    .title-desc,.paragraph {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14rem;
      color: #666666;
      line-height: 22rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
    .paragraph {
      margin-bottom: 20rem;
      .paragraph-index {
        color: #333333;
      }
      .paragraph-btn {
        color: #0366d6;
        text-decoration: underline;
        word-break: break-all;
      }
    }
    .paragraph-title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 14rem;
      color: #333333;
      line-height: 22rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 10rem;
    }
    .bold {
      font-weight: bold;
      color: #333333
    }
  }
}
</style>